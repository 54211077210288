import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect } from "react";
import SidebarToggleButton from "../SidebarToggleButton/SidebarToggleButton";
import styles from "./Sidebar.module.scss";
import LoyaltyStat from "../LoyaltyStat/LoyaltyStat";
import Spacer from "../Spacer/Spacer";
import config from "config";
import { HiArrowDown, HiArrowUp, HiOutlineDocumentText, HiOutlineHome, HiOutlineSpeakerphone } from "react-icons/hi";
import { isSet } from "../../helpers";
import { actions, useAppDispatch, useAppSelector } from "wintrado-api";
import classNames from "classnames";
import SidebarNavItem from "components/Sidebar/SidebarNavItem";
import { useIsTablet } from "utils/responsiveHelpers";
import { supportsCoinsSelector } from "wintrado-api/src/selectors";
import BrandLogo from "components/BrandLogo/BrandLogo";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
    var _a;
    const isSidebarCollapsed = useAppSelector((state) => state.settings.isSidebarCollapsed);
    const supportsCoins = useAppSelector(supportsCoinsSelector);
    const dispatch = useAppDispatch();
    const isTablet = useIsTablet();
    useEffect(() => {
        if (isTablet) {
            dispatch(actions.setIsSidebarCollapsed(true));
        }
    }, [dispatch, isTablet]);
    const handleSidebarCollapseToggle = useCallback((collapsed) => {
        dispatch(actions.setIsSidebarCollapsed(collapsed));
    }, [dispatch]);
    return (_jsxs("aside", Object.assign({ className: classNames(styles.sidebar, {
            [styles.sidebarCollapsed]: isSidebarCollapsed
        }) }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: classNames(styles.logoHolder, {
                            [styles.logoHolderOpened]: !isSidebarCollapsed
                        }) }, { children: _jsx(NavLink, Object.assign({ to: "/" }, { children: _jsx(BrandLogo, { useRectangular: true }) })) })), _jsx(Spacer, { x: "medium" }), _jsx(SidebarToggleButton, { collapsed: isSidebarCollapsed, onToggle: handleSidebarCollapseToggle })] })), _jsx(Spacer, { y: "extraLarge" }), supportsCoins && (_jsxs(_Fragment, { children: [_jsx(LoyaltyStat, { isCollapsed: isSidebarCollapsed }), _jsx(Spacer, { y: "extraLarge" })] })), _jsx(SidebarNavItem, { to: "/trading", icon: _jsx(HiOutlineHome, {}), labelKey: "main.trading", isCollapsed: isSidebarCollapsed }), isSet(config.cashier.depositURL) && (_jsx(SidebarNavItem, { to: "/deposit", icon: _jsx(HiArrowUp, {}), labelKey: "main.deposit", isCollapsed: isSidebarCollapsed })), isSet(config.cashier.withdrawURL) && (_jsx(SidebarNavItem, { to: "/withdraw", icon: _jsx(HiArrowDown, {}), labelKey: "main.withdraw", isCollapsed: isSidebarCollapsed })), _jsx(SidebarNavItem, { to: "/account-statement", icon: _jsx(HiOutlineDocumentText, {}), labelKey: "main.account_statement", isCollapsed: isSidebarCollapsed }), isSet(config.affiliatePortalUrl) && (_jsx(SidebarNavItem, { to: config.affiliatePortalUrl, icon: _jsx(HiOutlineSpeakerphone, {}), labelKey: "main.marketing", isCollapsed: isSidebarCollapsed })), (_a = config.customPagesConfig) === null || _a === void 0 ? void 0 : _a.map(({ pathName, titleKey, icon: Icon }) => (_jsx(SidebarNavItem, { to: pathName, labelKey: titleKey, icon: _jsx(Icon, {}), isCollapsed: isSidebarCollapsed }, pathName)))] })));
};
export default React.memo(Sidebar);
